import React, { useState, useEffect, useContext } from "react"
import { client } from "../sanity"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import Layout from "../components/layout"
import { Flex, Box, Title, Button, Text, Label } from "../components/system"

import { Context } from "../utils/Provider"
import Option from "../components/Option"
import Seo from "../components/seo"

const Product = ({ data }) => {
  const [size, setSize] = useState(null)
  const [stock, setStock] = useState(null)
  const [state, dispatch] = useContext(Context)
  const [loading, setLoading] = useState(true)

  const { name, images, _id, price, stripe, sizes, brand, description, fit } =
    data.sanityProduct

  const check = typeof window !== "undefined"
  const storage =
    typeof window !== "undefined" && window.localStorage.getItem("products")

  useEffect(() => {
    // Get Stock
    const query = "*[_id == $id]{empty,sizes}"
    const params = { id: _id }
    client
      .fetch(query, params)
      .then(data => setStock(data[0]))
      .then(setTimeout(() => setLoading(false), 1000))
  }, [state, _id])

  const addProduct = id => {
    const info = {
      cartId: uuidv4(),
      id: id,
      img: images[0].asset.gatsbyImageData,
      name: name,
      brand: brand.name,
      price: price,
      size: size,
      stripe: stripe,
    }
    // check if product exists in that size
    dispatch({ type: "ADD_TO_CART", payload: info })
    // update quantity
    let products = []
    if (storage) {
      products = JSON.parse(storage)
    }
    products.push(info)
    check && window.localStorage.setItem("products", JSON.stringify(products))
  }

  return (
    <Layout dark>
      <Seo
        title={`${brand.name} - ${name}`}
        description={description}
        image={images[0].asset.src}
      />
      <Box>
        <Flex alignItems="flex-start" flexDirection={["column", "row", "row"]}>
          <Box
            style={{
              whiteSpace: "nowrap",
              overflowX: "scroll",
              overflowY: "hidden",
            }}
            width={["100%", "40%", "50%"]}
            flexDirection={["row", "column"]}
            mr="auto"
            justifyContent="flex-start"
            mt={[120, 0]}
          >
            {images.map((image, key) => (
              <Box width="100%" key={key}>
                <GatsbyImage
                  image={image.asset.gatsbyImageData}
                  alt={brand.name}
                />
              </Box>
            ))}
          </Box>

          <Flex
            p={[4, 5]}
            width={["100%", "60%", "50%"]}
            height={["100%", "100vh"]}
            flexDirection="column"
            alignItems="flex-start"
            alignSelf="flex-start"
            position={["relative", "sticky"]}
            // right={0}
            top={0}
          >
            <Title
              textAlign="left"
              mr={[0, 6]}
              mb={0}
              fontWeight="normal"
              fontSize={[5, 5, 6]}
            >
              {name}
            </Title>
            <Text textAlign="left" color="faded" mb={4}>
              {brand.name}
            </Text>

            {stock?.empty ? (
              <Label color="red">Out of Stock</Label>
            ) : (
              <>
                {loading ? (
                  <Flex justifyContent="flex-start" flexWrap="wrap" mt={2}>
                    {sizes.map((s, key) => (
                      <Box
                        key={key}
                        bg="faded"
                        opacity={0.4}
                        width={50}
                        height={50}
                        borderRadius={3}
                        mr={3}
                        mb={3}
                      />
                    ))}
                  </Flex>
                ) : (
                  <Flex justifyContent="flex-start" flexWrap="wrap" mt={2}>
                    {stock?.sizes.map((s, key) => (
                      <Option
                        key={key}
                        size={s.title}
                        available={!s.stock}
                        onClick={() => !s.stock && setSize(s.title)}
                        selected={size === s.title}
                      />
                    ))}
                  </Flex>
                )}
                <a
                  href="https://cdn.sanity.io/files/ybn5jal1/production/16025378df7d279ada61b7a21b8fa63fea3c159a.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Label down color="faded" fontSize={2} mb={4}>
                    Size Guide ->
                  </Label>
                </a>

                {/* <Label down color="black" fontSize={2}>
                  Free Shipping
                </Label> */}

                <Label textAlign="left" mt={3} fontSize={3}>
                  £{price}
                </Label>
                <Button
                  mt={4}
                  disabled={!size}
                  onClick={() => addProduct(_id)}
                  bg="black"
                  hbg="#404040"
                  color="white"
                  hcolor="white"
                  width={["100%", 200]}
                >
                  {size ? "Add to Cart" : "Select a size"}
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </Box>

      <Flex justifyContent="flex-start" alignItems="flex-start" flexWrap="wrap">
        {description && (
          <Flex
            p={[4, 5, 6]}
            justifyContent="flex-start"
            alignItems="flex-start"
            flexDirection="column"
            width={["100%", "100%", "50%"]}
          >
            <Title fontSize={4} u>
              Product Details
            </Title>
            <Text textAlign="left">{description}</Text>
          </Flex>
        )}
        {fit && (
          <Flex
            p={[4, 5, 6]}
            justifyContent="flex-start"
            alignItems="flex-start"
            flexDirection="column"
            width={["100%", "100%", "50%"]}
          >
            <Title fontSize={4} u>
              Size & Fit
            </Title>
            <Text textAlign="left">{fit}</Text>
          </Flex>
        )}
      </Flex>
    </Layout>
  )
}

export default Product

export const pageQuery = graphql`
  query product($slug: String) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      _id
      name
      price
      stripe
      sizes {
        stock
      }
      slug {
        current
      }
      brand {
        name
      }
      description
      fit
      images {
        asset {
          gatsbyImageData(aspectRatio: 0.8)
        }
      }
    }
  }
`
