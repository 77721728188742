import React, { useState } from "react"
import { Flex, Label } from "../system"

const Option = ({ size, available, onClick, selected }) => {
  const [hover, setHover] = useState(false)
  return (
    <Flex
      onClick={onClick}
      border={`2px solid ${available ? "black" : "#CECECE"}`}
      height={50}
      width={50}
      minWidth={50}
      mr={3}
      mb={3}
      borderRadius={3}
      color={available ? "black" : "faded"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      bg={
        hover ? (available ? "black" : "white") : selected ? "black" : "white"
      }
      color={
        hover
          ? available
            ? "white"
            : "faded"
          : available
          ? selected
            ? "white"
            : "black"
          : "faded"
      }
      style={{ cursor: available ? "pointer" : "not-allowed" }}
    >
      <Label>{size}</Label>
    </Flex>
  )
}

export default Option
